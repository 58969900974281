import React from 'react';
import { Link } from 'gatsby';
import { Comparison } from '../../types';

interface Props {
  comparison: Comparison;
}

const ProductComparisonCard: React.FC<Props> = ({ comparison }) => (
  <Link
    to={`/${comparison.data.slug}/`}
    className="block overflow-hidden transition bg-gray-100 border rounded-lg shadow-xl hover:shadow-lg hover:border-main mb-7 border-gray"
  >
    <div className="relative">
      <div className="overflow-hidden bg-white" style={{ height: '250px' }}>
        <img
          src="/images/icon.png"
          alt={comparison.data.name}
          className="object-contain h-full"
          width="250"
          height="250"
        />
      </div>
    </div>
    <h4 className="px-3 py-2">{comparison.data.name}</h4>
  </Link>
);

export default ProductComparisonCard;
